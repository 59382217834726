<template>
  <Layout :tituloPagina="'Familias de artículos | ' + (modo == 'nuevo'? 'Nueva ': 'Edición de ') + 'familia'">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de' }} cliente
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Familia de artículo</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="familia.familia"
                  placeholder="0"
                  ref="familia"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                />
              </div>
              <div class="col-md-6">
                <label>División</label>
                <input 
                  type="text" 
                  v-model="familia.division" 
                  class="form-control" 
                  placeholder="División"
                  ref="division" 
                  maxlength="45"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <textarea 
                  class="form-control"
                  placeholder="Descripción de la familia de artículos"
                  v-model="familia.descripcion"
                  rows="5" 
                  ref="descripcion"
                  maxlength="200">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'

export default {
  name: 'EdicionFamiliaArticulos',
  components: {
    Layout
  },       
  data: function() {
    return {
      modo: 'nuevo',
      familia: {
        familia: null,
        division: '',
        descripcion: ''
      },
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this
    console.log(this.$route.path.indexOf('familias-de-articulos/nueva'))
    // Determinación del modo
    if (this.$route.path.indexOf('familias-de-articulos/nueva') != -1) this.modo = 'nuevo'
    else this.modo = 'edicion'

    if (this.modo == 'edicion') this.cargarFamiliaArticulos()
  },
  methods: {
    actualizar: function() {
      var self = this
      
      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return
      
      var familia = self.familia

      FamiliaArticulosSrv.actualizar(familia).then(response => {
        iu.msg.success('Familia de artículos actualizada')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la familia'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarFamiliaArticulos: function() {
      let self = this

      let idFamilia = self.$route.params.id

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la familia de artículos '+idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },
    
    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      FamiliaArticulosSrv.guardar(self.familia).then(response=>{
        iu.msg.success('Se guardó correctamente')
        self.cerrar()
        self.modo = 'edicion'
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la familia de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    verificarDatosCorrectos(){
      var self = this,
          regex = /^[ a-zA-Z0-9_-áÁéÉíÍóÓúÚñÑ]{3,45}$/;

      if (self.familia.familia == null) {
        iu.msg.warning('Es necesario ingresar una familia de artículo')
        self.$refs.familia.focus()
        self.bandera_spinner = false
        return false
      }
      
      if (self.familia.familia < 1) {
        iu.msg.warning('No se aceptan valores negativos en la familia de artículo')
        self.$refs.familia.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.familia.division == '') {
        iu.msg.warning('Es necesario ingresar una la división')
        self.$refs.division.focus()
        self.bandera_spinner = false
        return false
      }

      if(!regex.test(self.familia.division)){
        iu.msg.warning('La división no permite caracteres especiales y la longitud no puede ser menor de 3 ni mayor a 45')
        self.$refs.division.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.familia.descripcion == '') {
        iu.msg.warning('Es necesario ingresar una descripción')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.familia.descripcion.length > 200) {
        iu.msg.warning('No se aceptan más de 200 caracteres en la descripción')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>